import "core-js/modules/es6.function.name";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-container", {
    staticClass: "layout-cont"
  }, [_c("el-container", {
    class: [_vm.isSider ? "openside" : "hideside", _vm.isMobile ? "mobile" : ""]
  }, [_c("el-row", {
    class: [_vm.isShadowBg ? "shadowBg" : ""],
    nativeOn: {
      click: function click($event) {
        return _vm.changeShadow();
      }
    }
  }), _vm._v(" "), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: this.$store.user.isAdmin || this.userInfo.IsAdmin,
      expression: "this.$store.user.isAdmin || this.userInfo.IsAdmin"
    }],
    staticClass: "menu-toggle",
    on: {
      click: _vm.totalCollapse
    }
  }, [_vm.isCollapse ? _c("i", {
    staticClass: "el-icon-right"
  }) : _c("i", {
    staticClass: "el-icon-back"
  })]), _vm._v(" "), _c("el-aside", {
    staticClass: "main-cont main-left"
  }, [_c("div", {
    staticClass: "title"
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isCollapse,
      expression: "isCollapse"
    }],
    staticClass: "logoimg",
    attrs: {
      alt: "",
      src: require("@/assets/logo.png")
    }
  }), _vm._v(" "), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isCollapse,
      expression: "!isCollapse"
    }],
    staticClass: "logoimg",
    attrs: {
      alt: "",
      src: require("@/assets/Porsche_crest_color_xs_rgb.png")
    }
  })]), _vm._v(" "), _c("Aside", {
    staticClass: "aside"
  })], 1), _vm._v(" "), _c("el-main", {
    staticClass: "main-cont main-right"
  }, [_c("div", {
    staticClass: "topfix"
  }, [_c("el-header", {
    staticClass: "header-cont"
  }, [_c("span", {
    staticStyle: {
      "font-size": "28px",
      "font-weight": "bold"
    }
  }, [_vm._v("iData")]), _vm._v(" "), _c("div", {
    staticClass: "fl-right right-box"
  }, [_c("Search"), _vm._v(" "), _c("el-dropdown", [_c("span", {
    staticClass: "header-avatar"
  }, [_c("span", {
    staticStyle: {
      "margin-left": "5px"
    }
  }, [_vm._v(_vm._s(_vm.userInfo.nickName))]), _vm._v(" "), _c("i", {
    staticClass: "el-icon-arrow-down"
  })]), _vm._v(" "), _c("el-dropdown-menu", {
    staticClass: "dropdown-group",
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-s-custom"
    },
    nativeOn: {
      click: function click($event) {
        _vm.showPassword = true;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("layout.reset_password")))]), _vm._v(" "), _c("el-dropdown-item", {
    attrs: {
      icon: "el-icon-table-lamp"
    },
    nativeOn: {
      click: function click($event) {
        return _vm.LoginOut.apply(null, arguments);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("layout.logout")))])], 1)], 1)], 1)])], 1), _vm._v(" "), _c("div", {
    staticClass: "layout-content",
    class: {
      home: _vm.isDashboard
    }
  }, [_c("transition", {
    attrs: {
      duration: {
        enter: 800,
        leave: 100
      },
      mode: "out-in",
      name: "el-fade-in-linear"
    }
  }, [_c("div", {
    staticClass: "mid-fix",
    class: {
      collapsed: _vm.isCollapse,
      mobile: _vm.isMobile
    }
  }, [_c("HistoryComponent")], 1)]), _vm._v(" "), _c("transition", {
    attrs: {
      mode: "out-in",
      name: "el-fade-in-linear"
    }
  }, [_c("keep-alive", [_vm.$route.meta.keepAlive && _vm.$route.name != "reportcardDetail" ? _c("router-view", {
    staticClass: "admin-box"
  }) : _vm._e(), _vm._v(" "), _vm.$route.meta.keepAlive && _vm.$route.name == "reportcardDetail" ? _c("router-view", {
    staticClass: "cardDetail-box"
  }) : _vm._e()], 1)], 1), _vm._v(" "), _c("transition", {
    attrs: {
      mode: "out-in",
      name: "el-fade-in-linear"
    }
  }, [!_vm.$route.meta.keepAlive && _vm.$route.name != "reportcardDetail" ? _c("router-view", {
    staticClass: "admin-box"
  }) : _vm._e(), _vm._v(" "), !_vm.$route.meta.keepAlive && _vm.$route.name == "reportcardDetail" ? _c("router-view", {
    staticClass: "cardDetail-box"
  }) : _vm._e()], 1)], 1)])], 1), _vm._v(" "), _c("el-dialog", {
    attrs: {
      visible: _vm.showPassword,
      title: _vm.$t("layout.reset_password"),
      width: "360px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showPassword = $event;
      },
      close: _vm.clearPassword
    }
  }, [_c("el-form", {
    ref: "modifyPwdForm",
    attrs: {
      size: "small",
      model: _vm.pwdModify,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("layout.old_password"),
      prop: "password"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.password,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "password", $$v);
      },
      expression: "pwdModify.password"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("layout.new_password"),
      prop: "newPassword"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.newPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "newPassword", $$v);
      },
      expression: "pwdModify.newPassword"
    }
  })], 1), _vm._v(" "), _c("el-form-item", {
    attrs: {
      minlength: 6,
      label: _vm.$t("layout.confirm_password"),
      prop: "confirmPassword"
    }
  }, [_c("el-input", {
    attrs: {
      "show-password": ""
    },
    model: {
      value: _vm.pwdModify.confirmPassword,
      callback: function callback($$v) {
        _vm.$set(_vm.pwdModify, "confirmPassword", $$v);
      },
      expression: "pwdModify.confirmPassword"
    }
  })], 1)], 1), _vm._v(" "), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function click($event) {
        _vm.showPassword = false;
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]), _vm._v(" "), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.savePassword
    }
  }, [_vm._v(_vm._s(_vm.$t("common.confirm")))])], 1)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };