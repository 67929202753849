import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import _defineProperty from "/gva_web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Aside from '@/view/layout/aside';
import HistoryComponent from '@/view/layout/aside/historyComponent/history';
// import Screenfull from '@/view/layout/screenfull';
import Search from '@/view/layout/search/search';
// import BottomInfo from '@/view/layout/bottomInfo/bottomInfo';
import { mapGetters, mapActions } from 'vuex';
import { changePassword } from '@/api/user';
export default {
  name: 'Layout',
  data: function data() {
    var _this = this;
    return {
      show: false,
      isCollapse: true,
      isSider: false,
      isMobile: false,
      isShadowBg: false,
      showPassword: false,
      pwdModify: {},
      rules: {
        password: [{
          required: true,
          message: this.$t('layout.old_password_required'),
          trigger: 'blur'
        }, {
          min: 6,
          message: this.$t('layout.old_password_min'),
          trigger: 'blur'
        }],
        newPassword: [{
          required: true,
          message: this.$t('layout.new_password_required'),
          trigger: 'blur'
        }, {
          min: 6,
          message: this.$t('layout.new_password_min'),
          trigger: 'blur'
        }],
        confirmPassword: [{
          required: true,
          message: this.$t('layout.confirm_password_required'),
          trigger: 'blur'
        }, {
          min: 6,
          message: this.$t('layout.confirm_password_min'),
          trigger: 'blur'
        }, {
          validator: function validator(rule, value, callback) {
            if (value !== _this.pwdModify.newPassword) {
              callback(new Error(_this.$t('layout.confirm_password_valid')));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }]
      },
      value: ''
    };
  },
  components: {
    Aside: Aside,
    HistoryComponent: HistoryComponent,
    // Screenfull,
    Search: Search
    // BottomInfo
  },
  methods: _objectSpread(_objectSpread({}, mapActions('user', ['LoginOut'])), {}, {
    totalCollapse: function totalCollapse() {
      this.isCollapse = !this.isCollapse;
      this.isSider = !this.isCollapse;
      this.isShadowBg = !this.isCollapse;
      this.$bus.emit('collapse', this.isCollapse);
    },
    toPerson: function toPerson() {
      this.$router.push({
        name: 'person'
      });
    },
    changeShadow: function changeShadow() {
      this.isShadowBg = !this.isShadowBg;
      this.isSider = !!this.isCollapse;
      this.totalCollapse();
    },
    savePassword: function savePassword() {
      var _this2 = this;
      this.$refs.modifyPwdForm.validate(function (valid) {
        if (valid) {
          changePassword({
            username: _this2.userInfo.userName,
            password: _this2.pwdModify.password,
            newPassword: _this2.pwdModify.newPassword
          }).then(function () {
            _this2.$message.success('修改密码成功！');
            _this2.showPassword = false;
          });
        } else {
          return false;
        }
      });
    },
    clearPassword: function clearPassword() {
      this.pwdModify = {
        password: '',
        newPassword: '',
        confirmPassword: ''
      };
      this.$refs.modifyPwdForm.clearValidate();
    }
  }),
  computed: _objectSpread(_objectSpread({}, mapGetters('user', ['userInfo'])), {}, {
    title: function title() {
      return this.$route.meta.title || '当前页面';
    },
    matched: function matched() {
      return this.$route.matched;
    },
    isDashboard: function isDashboard() {
      var isDashboard = this.$route.name === "dashboard";
      return isDashboard;
    }
  }),
  mounted: function mounted() {
    var _this3 = this;
    var screenWidth = document.body.clientWidth;
    if (screenWidth < 1000) {
      this.isMobile = true;
      this.isSider = false;
      this.isCollapse = true;
    } else if (screenWidth >= 1000 && screenWidth < 1200) {
      this.isMobile = false;
      this.isSider = false;
      this.isCollapse = true;
    } else {
      this.isMobile = false;
      this.isSider = true;
      this.isCollapse = false;
    }
    this.isCollapse = true;
    this.isSider = false;
    this.$bus.emit('collapse', this.isCollapse);
    this.$bus.emit('mobile', this.isMobile);
    window.onresize = function () {
      return function () {
        var screenWidth = document.body.clientWidth;
        if (screenWidth < 1000) {
          _this3.isMobile = true;
          _this3.isSider = false;
          _this3.isCollapse = true;
        } else if (screenWidth >= 1000 && screenWidth < 1200) {
          _this3.isMobile = false;
          _this3.isSider = false;
          _this3.isCollapse = true;
        } else {
          _this3.isMobile = false;
          _this3.isSider = true;
          _this3.isCollapse = false;
        }
        _this3.isCollapse = true;
        _this3.isSider = false;
        _this3.$bus.emit('collapse', _this3.isCollapse);
        _this3.$bus.emit('mobile', _this3.isMobile);
      }();
    };
  }
};